<template>
	<div class="">
		<div class="mode-center">
			<div
				class="w-11/12 grid grid-cols-2 gap-32 pt-170 items-baseline mx900:grid-cols-1 mn640:pt-280 mx640:pb-100 text-pine"
			>
				<div class="">
					<h1 class="h1-text mx640:text-9 mb-16" v-if="data_hero.title">
						{{ data_hero.title }}
					</h1>
					<div
						v-if="data_details.text"
						v-html="data_details.text"
						class="rich-text anime-hover text-24 font-inter"
					></div>
				</div>
				<div class="mb-100">
					<h3
						class="text-24 font-tiempos_r pb-12 mx640:text-6 "
						v-if="data_hero.text"
						v-html="data_hero.text"
					></h3>

					<div class="relative" id="wrap_form_steps">
						<div class="relative hidden" id="wrap_form_thanks">
							<div class="text-pine text-24">
								{{ form_copy.txt_form_done }}
							</div>
						</div>
						<div class="relative" id="wrap_form">
							<FormulateForm
								class="font-inter text-pine mx900:w-70% mx640:w-full"
								@submit="submitHandler"
							>
								<FormulateInput
									class="mb-8"
									input-class="border-b border-spanish w-full mb-2 mt-2 focus:outline-none"
									error-class="text-crab"
									name="name"
									validation="required"
									:placeholder="form_copy.name.placeholder"
									:label="form_copy.name.label"
									:validation-name="form_copy.name.label"
								/>
								<FormulateInput
									class="mb-8"
									input-class="border-b border-spanish w-full mb-2 mt-2 focus:outline-none"
									error-class="text-crab"
									type="email"
									name="email"
									validation="required|email"
									:placeholder="form_copy.email.placeholder"
									:label="form_copy.email.label"
									:validation-name="form_copy.email.label"
								/>
								<FormulateInput
									class="mb-8"
									input-class="border-b border-spanish w-full mb-2 mt-2 focus:outline-none"
									error-class="text-crab"
									name="phone"
									type="tel"
									:placeholder="form_copy.phone.placeholder"
									:label="form_copy.phone.label"
									:validation-name="form_copy.phone.label"
								/>
								<FormulateInput
									class="mb-8"
									name="role"
									type="radio"
									element-class="flex justify-between mt-2 mb-2"
									error-class="text-crab"
									:label="form_copy.role.label"
									:options="{
										investor: form_copy.role.investor,
										entreprenuer: form_copy.role.entrepreneur,
										other: form_copy.role.other,
									}"
									:validation-name="form_copy.role.label"
								/>
								<FormulateInput
									class="mb-8"
									input-class="border-b border-spanish w-full mb-2 mt-2 focus:outline-none"
									error-class="text-crab"
									name="subject"
									validation="required"
									:placeholder="form_copy.subject.placeholder"
									:label="form_copy.subject.label"
									:validation-name="form_copy.subject.label"
								/>
								<FormulateInput
									class="mb-8"
									input-class="border-b border-spanish w-full mb-2 mt-2 focus:outline-none"
									error-class="text-crab"
									name="message"
									validation="required"
									:placeholder="form_copy.message.placeholder"
									:label="form_copy.message.label"
									:validation-name="form_copy.message.label"
								/>
								<vue-recaptcha
									class="form-recaptcha mb-2"
									sitekey="6Lc_eI8bAAAAAJLczzRgGGrlZ0BZRs37cYw01gUT"
									:loadRecaptchaScript="true"
									:language="recaptchaLanguage"
									@verify="checkRecaptcha"
								>
								</vue-recaptcha>
								<div
									v-if="recaptchaVerified == false"
									class="text-crab mn640:text-center"
								>
									{{ recaptcha.recaptcha_error }}
								</div>
								<FormulateInput
									class="flex justify-end"
									input-class="text-xl font-inter mb-2 mt-4 text-pine py-2 mx640:text-4.5 focus:outline-none"
									type="submit"
									:label="form_copy.submit.label"
									id="submit_button"
								/>
							</FormulateForm>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class=" bg-mandarin scan-section hidden" v-if="data_want_more.content">
			<div
				class="mode-center text-pine text-30 py-16 font-tiempos_r mx900:text-4 mx640:text-6 anime-hover-white"
				v-html="data_want_more.content"
			></div>
		</div>
	</div>
</template>

<script>
	import { globals } from "@/modules/globals";
	import api from "@/modules/api";
	import { EventBus } from "@/modules/event_bus";
	import VueRecaptcha from "vue-recaptcha";
	import gsap from "gsap";
	import { ScrollToPlugin } from "gsap/ScrollToPlugin";
	gsap.registerPlugin(ScrollToPlugin);

	var thisVue = null;

	export default {
		name: "Contact",
		components: { VueRecaptcha },

		data() {
			return {
				data_want_more: globals.segments["quick-scan"],
				data_hero: {},
				data_details: {},
				recaptchaVerified: null,
				recaptchaLanguage: globals.language,
				form_copy: {
					name: {
						label:
							globals.microcopy.contact_form["name"].label[globals.language],
						placeholder:
							globals.microcopy.contact_form["name"].placeholder[
								globals.language
							],
					},
					email: {
						label:
							globals.microcopy.contact_form["email"].label[globals.language],
						placeholder:
							globals.microcopy.contact_form["email"].placeholder[
								globals.language
							],
					},
					phone: {
						label:
							globals.microcopy.contact_form["phone"].label[globals.language],
						placeholder:
							globals.microcopy.contact_form["phone"].placeholder[
								globals.language
							],
					},
					subject: {
						label:
							globals.microcopy.contact_form["subject"].label[globals.language],
						placeholder:
							globals.microcopy.contact_form["subject"].placeholder[
								globals.language
							],
					},
					message: {
						label:
							globals.microcopy.contact_form["message"].label[globals.language],
						placeholder:
							globals.microcopy.contact_form["message"].placeholder[
								globals.language
							],
					},
					role: {
						label:
							globals.microcopy.contact_form["role"].label[globals.language],
						investor:
							globals.microcopy.contact_form["role"].investor[globals.language],
						entrepreneur:
							globals.microcopy.contact_form["role"].entrepreneur[
								globals.language
							],
						other:
							globals.microcopy.contact_form["role"].other[globals.language],
					},
					submit: {
						label:
							globals.microcopy.contact_form["submit"].label[globals.language],
					},
					txt_form_done:
						globals.microcopy.contact_form["txt_form_done"][globals.language],
				},
				recaptcha: {
					recaptcha_error:
						globals.microcopy.contact_form["recaptcha_error"][globals.language],
				},
			};
		},
		beforeRouteEnter(to, from, next) {
			let dataFiles = 1;

			let sectionsData = null;
			let json_file_sections =
				"/json/sections/sections_contact_" +
				globals.language +
				".json?v=" +
				globals.content_version;
			fetch(json_file_sections)
				.then((response) => response.json())
				.then(function(data) {
					sectionsData = data;
					afterJsonLoaded();
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
				});

			let countLoaded = 0;
			function afterJsonLoaded() {
				countLoaded++;
				if (countLoaded == dataFiles) {
					next((vm) => vm.setData(sectionsData));
				}
			}
		},
		mounted() {
			thisVue = this;
			// this.loadRecaptcha(globals.language);
		},
		methods: {
			checkRecaptcha() {
				this.recaptchaVerified = true;
				console.log(this.recaptchaVerified);
			},
			setData(sectionsData) {
				this.data_hero = sectionsData["enquiries"];
				this.data_details = sectionsData["contact-details"];
			},
			submitHandler(formdata) {
				if (this.recaptchaVerified) {
					gsap.set("#submit_button", {
						opacity: 0.5,
						"pointer-events": "none",
					});
					formSend(formdata);
				} else {
					this.recaptchaVerified = false;
				}
			},
		},
	};

	var lv = {};
	var formVars = {};
	formVars.dataTask = "contact_mail";
	formVars.dataEvent = "data_contact_mail";
	formVars.apiBusy = false;

	EventBus.$on(formVars.dataEvent, (e) => {
		api_done(e);
	});

	function formSend(form_data) {
		if (formVars.apiBusy) {
			return;
		}
		formVars.apiBusy = true;
		api.call({
			task: formVars.dataTask,
			event: formVars.dataEvent,
			global: false,
			formData: form_data,
		});
	}
	function api_done(d) {
		formVars.apiBusy = false;
		if (d.result == "nok") {
			formError(d.data.respond);
			return;
		}
		if (d.result == "ok") {
			formDone();
			//console.log("............. mail sent success .............")
			return;
		}
		// it's possible that some unidentified eroor occurred
		//formVars.error_message = "Er is helaas iets misgegaan..."
	}
	function formError(e) {
		console.log("............. mail sent error .............");
	}

	function formDone() {
		lv.targ = "#wrap_form_steps";
		gsap.set(lv.targ, { height: "auto" });
		lv.targ = "#wrap_form_steps";
		lv.start_height = gsap.getProperty(lv.targ, "height");
		lv.targ = "#wrap_form";
		gsap.set(lv.targ, { display: "none" });
		lv.targ = "#wrap_form_thanks";
		gsap.set(lv.targ, { display: "block" });
		lv.targ = "#wrap_form_steps";
		lv.end_height = gsap.getProperty(lv.targ, "height");
		lv.targ = "#wrap_form_thanks";
		gsap.set(lv.targ, { display: "none" });
		lv.targ = "#wrap_form";
		gsap.set(lv.targ, { display: "block" });

		lv.targ = "#wrap_form_steps";
		gsap.to(lv.targ, {
			duration: 0.1,
			height: lv.start_height,
			ease: "power3.inOut",
		});
		lv.targ = "#wrap_form";
		gsap.to(lv.targ, {
			delay: 0.1,
			duration: 0.5,
			opacity: 0,
			display: "none",
			ease: "power3.inOut",
		});
		lv.targ = "#wrap_form_steps";
		gsap.to(lv.targ, {
			delay: 0.6,
			duration: 0.5,
			height: lv.end_height,
			ease: "power3.inOut",
		});
		lv.targ = "#wrap_form_thanks";
		gsap.to(lv.targ, {
			delay: 1.1,
			duration: 0.5,
			opacity: 1,
			display: "block",
			ease: "power3.inOut",
		});
		lv.targ = "#wrap_form_steps";
		gsap.to(lv.targ, {
			delay: 1.2,
			duration: 0.1,
			height: "auto",
			ease: "power3.inOut",
		});
		lv.targ = "#wrap_form_steps";
		gsap.to(window, {
			delay: 0.6,
			duration: 0.5,
			scrollTo: { y: lv.targ, offsetY: 200 },
			ease: "power3.inOut",
		});
	}
</script>

<style>
	.formulate-input-element--checkbox {
		display: inline;
	}
	.formulate-input-element--radio {
		display: inline;
	}
	.scan-section {
		background-image: url("../assets/sun-2-pineapple.svg");
		background-repeat: no-repeat;
		background-position: right;
		background-position-x: 45vw;
		background-attachment: fixed;
	}
	.form-recaptcha div {
		margin: 0 auto;
	}
	@media only screen and (max-width: 640px) {
		.scan-section {
			background-position-x: 15vw;
		}
		.form-recaptcha {
			transform: translate(-25px, 0px) scale(0.9);
		}
		.form-recaptcha div {
			margin: unset;
		}
	}
	@media only screen and (max-width: 480px) {
		.form-recaptcha {
			transform: translate(-20px, 0px) scale(0.9);
		}
	}
</style>
